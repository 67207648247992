import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'
import DeleteModal from 'pages/parts/DeleteModal'
import TableControl from 'pages/parts/TableControl'

import { PGET, DELETE } from 'helpers/api'
import { formatDate, formatDateWithTime, formatMoney } from 'helpers/helpers'

function Index() {
	const URL = "/services/web/api/sd-purchase-pageList"
	const { t } = useTranslation()

	const reduxSettings = useSelector(state => state.settings)

	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])

	async function getData() {
		const response = await PGET(URL)
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
			setData(response.data)
		}
	}


	async function paginate(data) {
		const response = await PGET(URL, { page: data.selected, size: reduxSettings.size })
		setData(response.data);
	}


	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('ORDERS_SD')}
				</h4>
				<div className="d-flex">
					<Link className="btn-rounded btn btn-primary" to="/sd-purchase/create">
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</Link>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('ID')}</th>
									<th>{t('POS')}</th>
									<th>{t('DEALER')}</th>
									<th className="text-center">{t('QUANTITY')}</th>
									<th className="text-center">{t('AMOUNT')}</th>
									<th className="text-center">{t('DATE_OF_RECEIPT')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.id}</td>
										<td>{item.posName}</td>
										<td>{item.dealerName}</td>
										<td className="text-center">{item.totalQuantity}</td>
										<td className="text-center">{formatMoney(item.totalAmount)}</td>
										<td className="text-center">{formatDateWithTime(item.createdDate)}</td>
										<td className="d-flex justify-content-center">
											<Link to={`/sd-purchase/view/${item.id}`} className="me-2">
												<div className="table-action-button table-action-primary-button">
													<i className="uil-eye"></i>
												</div>
											</Link>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
