import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DebounceInput } from 'react-debounce-input'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { GET, POST, PUT } from 'helpers/api'

function CreateUpdate() {
	const { id } = useParams();

	const { t } = useTranslation();
	const navigate = useNavigate();

	const reduxSettings = useSelector(state => state.settings)


	const [posId, setPosId] = useState([])
	const [poses, setPoses] = useState([])
	const [search, setSearch] = useState('')
	const [searchChild, setSearchChild] = useState('')
	const [searchDataChild, setSearchDataChild] = useState([])
	const [data, setData] = useState({name: "", id: "", token: "", posId: "", domain: ""})
	const [dataChild, setDataChild] = useState([])

	async function create() {
		var sendData = {
			'products': [],
		}
		sendData.name = data['name']
		sendData.domain = data['domain']
		if(data['id']) sendData['id'] = data['id']
		if(data['token']) sendData.token = data['token']
		sendData.posId = posId

		for (let i = 0; i < dataChild.length; i++) {
			sendData.products.push({
				'id': dataChild[i]['productId']
			})
		}

		console.log(sendData)

		const response = await POST('/services/web/api/dealer', sendData, { loader: true })
		if (response) {
			navigate('/dealer')
		}
		
		
	}

	async function searchProduct(value) {
		setSearchChild(value)
		if (value.length === 0) {
			setSearchDataChild([])
		} else {
			var response = await GET(
				"/services/web/api/product-dealer-helper/",
				{ 'search': value },
				{ loader: true }
			)

			if (response.length > 0) {
				// new product
				var productExistOnResponse = false
				for (let i = 0; i < response.length; i++) {
					if (response[i]['productBarcode'] !== value) {
						productExistOnResponse = true
					}
				}

				if (productExistOnResponse) {
					response.unshift({
						productBarcode: value,
						productDB: false,
						productName: value
					})
				}
				// new product

			}

			var responseCopy = [...response]
			var childDataCopy = [...dataChild]
			for (let i = 0; i < childDataCopy.length; i++) {
				responseCopy = responseCopy.filter(e => e.productBarcode !== childDataCopy[i]['productBarcode'])
			}
			response = [...responseCopy]

			if (response.length === 0) {
				var productExistNoResponse = false
				if (value !== data[0]['productBarcode']) {
					productExistNoResponse = true
				}
				if (productExistNoResponse) {
					for (let i = 0; i < childDataCopy.length; i++) {
						if (childDataCopy[i]['productBarcode'] === value) {
							productExistNoResponse = false
						}
					}
					if (!productExistNoResponse) {
						toast.error(t('ERROR') + ': ' + t('PRODUCT_ADDED'))
					}
				}
				if (productExistNoResponse) {
					response.push({
						productBarcode: value,
						productDB: false,
						productName: value
					})
					addToList(0, response)
					setSearchChild('')
				}
				return
			}

			if (/^\d+$/.test(value) && response.length === 1) {
				setSearchDataChild(response)
				addToList(0, response)
				setSearchChild('')
			} else {
				setSearchDataChild(response)
			}
		}
	}

	

	function addToList(index, arr = []) {
		var searchDataCopy;
		if (arr.length > 0) {
			searchDataCopy = arr
		} else {
			searchDataCopy = [...searchDataChild]
		}

		var dataCopy = [...dataChild]
		dataCopy.push(searchDataCopy[index])

		setDataChild(dataCopy)


		searchDataCopy.splice(index, 1)
		setSearchDataChild(searchDataCopy)

		setSearch('')
	}


	function deleteChild(index) {
		var dataChildCopy = [...dataChild]
		dataChildCopy.splice(index, 1)
		setDataChild(dataChildCopy)
	}

	async function getData() {
		const response = await GET(`/services/web/api/dealer/${id}`, {}, { loader: true })
		if (response) {

			
            // Update products state (dataChild)
            if (response.products && response.products.length > 0) {
				var mappedProducts = response.products.map(product => ({
					productId: product.id,
					productName: product.name,
					productBarcode: product.barCode,
				}));
                setDataChild(mappedProducts);
            } else {
                console.warn("No products found in the response.");
                setDataChild([]);
            }

            // Update main state (data) in one call
            setData({
                ...data, // Spread existing fields
                id: response.id || null,
                domain: response.domain || '',
                name: response.name || '',
                token: response.token || '', // Ensure token is handled if missing
            });

            // Update posId state if needed separately
            setPosId(response.posId || null);
		}
	}

	async function getPoses() {
		const temporaryPoses = await GET('/services/web/api/pos-helper')
		if (temporaryPoses) {
			setPoses(temporaryPoses)
		}

		if (temporaryPoses.length > 0) {
			setPosId(reduxSettings.posId ? reduxSettings.posId : temporaryPoses[0]['id'])
		}
	}

	useEffect(() => {
		getPoses()
		if(id) getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('DEALERS')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{id ? t('EDIT') : t('CREATE')}</h6>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-6">
					<div className="card min-h500">
						<div className="card-body">
							<div className="form-group">
								<label>{t('POS')}<span className="required-mark">*</span></label>
								<Select
									isDisabled={poses.length === 1 || id} // Fixed data.length -> poses.length
									options={poses}
									value={poses.find(option => option.id === posId)} // Access posId from `data`
									onChange={(option) => setPosId(option.id)} // Ensure proper key is updated
									placeholder=""
									noOptionsMessage={() => t('LIST_IS_EMPTY')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>

							<div className="form-group position-relative mb-0">
								<label>{t('DEALER')}<span className="required-mark">*</span></label>
								<input type="text" className="form-control" value={data.name} onChange={(e) => setData({ ...data, 'name': e.target.value })} />
							</div>
							<br></br>

							<div className="form-group position-relative mb-0">
								<label>{t('DOMAIN')}<span className="required-mark">*</span></label>
								<input type="text" className="form-control" value={data.domain} onChange={(e) => setData({ ...data, 'domain': e.target.value })} />
							</div>

							
						</div>
					</div>
				</div>
				<div className="col-md-6">
					<div className="card min-h500 p-3 vertical-between">
						<div className="h-100">
							<h3 className="mb-2">{t('PRODUCT_SELECTION')}</h3>
							<hr />
							<div className="form-group position-relative mb-0">
								<DebounceInput
									disabled={data?.name?.length === 0}
									value={searchChild}
									onChange={(e) => searchProduct(e.target.value)}
									type="text"
									className="form-control"
									placeholder={t('SEARCH') + '...'}
									debounceTimeout={1000}
								/>

								{searchDataChild?.length > 0 &&
									<i className="uil uil-times search-barcode" onClick={() => { setSearchDataChild([]); setSearchChild('') }}></i>
								}

								{searchDataChild?.length > 0 &&
									<span className="dropdown-search-menu">
										<div className="dropdown-menu-list">
											<span className="dropdown-menu-result">
												<div className="table-responsive p-3">
													<table className="table-striped table">
														<thead>
															<tr>
																<th>{t('NAME_OF_PRODUCT')}</th>
																<th className="text-center">{t('BARCODE')}</th>
																<th className="text-center">{t('ACTION')}</th>
															</tr>
														</thead>
														<tbody>
															{searchDataChild?.map((item, index) => (
																<tr key={index}>
																	<td>{item.productName}</td>
																	<td className="text-center">{item.productBarcode}</td>
																	<td className="d-flex justify-content-center">
																		<div className="table-action-button table-action-primary-button" onClick={() => addToList(index)}>
																			<i className="uil uil-plus"></i>
																		</div>
																	</td>
																</tr>
															))
															}
														</tbody>
													</table>
												</div>
											</span>
										</div>
									</span>
								}

							</div>

							<div className="table-responsive grouping-table">
								<table className="table">
									<thead>
										<tr>
											<th>{t('NAME_OF_PRODUCT')}</th>
											<th className="text-center">{t('BARCODE')}</th>
											<th className="text-center">{t('ACTION')}</th>
										</tr>
									</thead>
									<tbody>
										{dataChild?.map((item, index) => (
											<tr key={index}>
												<td>{item.productName}</td>
												<td className="text-center">{item.productBarcode}</td>
												<td>
													<div className="d-flex justify-content-center">
														<div className="table-action-button table-action-danger-button" onClick={() => deleteChild(index)}>
															<i className="uil-times"></i>
														</div>
													</div>
												</td>
											</tr>
										))
										}
									</tbody>
								</table>
							</div>
						</div>

						{ (posId && data.name && data.domain) &&
							<div className="d-flex flex-wrap items-center justify-content-end">
								<button type="button" className="btn btn-primary btn-rounded btn-wide"
									disabled={data?.length === 0 || dataChild?.length === 0}
									onClick={create}>
									{t("SAVE")}
								</button>
							</div>
						}
					</div>
				</div>
			</div>
		</>
	)
}

export default CreateUpdate

