import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Search from 'pages/parts/Search'
import DeleteModal from 'pages/parts/DeleteModal'
import { GET, DELETE, POST } from 'helpers/api'

function Index() {
	const URL = "/services/web/api/dealer-pageList"
	const { t } = useTranslation()

	const reduxSettings = useSelector(state => state.settings)

	const [modalDelete, setModalDelete] = useState(false)
	const [deleteModalParams, setDeleteModalParams] = useState({id: 0, index: 0 })
	const [data, setData] = useState([])

	async function getData() {
		const response = await GET(URL)
		if (response) {
			setData(response)
			console.log(data)
		}
	}

	async function generateToken(dilerId){
		const response = await POST('/services/web/api/generate-token/' + dilerId, {}, { loader: true })
		if (response) {
			getData()
		}
		
	}

	async function deleteItem() {
		const response = await DELETE("/services/web/api/dealer/"
			+ deleteModalParams.id, {}, { loader: true })
		if (response) {
			getData()
			setModalDelete(false)
		}
	}

	async function search(data, searchValue) {
		setData(data);
	}

	useEffect(() => {
		getData()
	}, []) 

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('DEALERS')}
				</h4>
				<div className="d-flex">
					<Link className="btn-rounded btn btn-primary" to="/dealer/create">
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</Link>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('POS')}</th>
									<th>{t('DEALER')}</th>
									<th>{t('DOMAIN')}</th>
									<th className="text-center">{t('JWT_TOKEN')}</th>
									<th className="text-center table-control">
										{t('ACTION')}
									</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td>{item.posName}</td>
										<td>{item.name}</td>
										<td>{item.domain}</td>
										<td className="text-center">
											{item.token ? (
												<div style={{ display: "inline-flex", alignItems: "center" }}>
													<span>{item.token.substring(0, 20)}...</span> {/* Display first 20 characters */}
													<div
													className="table-action-button table-action-primary-button"
													style={{ marginLeft: "10px", cursor: "pointer" }}
													onClick={() => navigator.clipboard.writeText(item.token)} // Copy full token
													>
													<i className="uil uil-copy me-2"></i>
													</div>
												</div>
												) : (
												<button
													type="button"
													className="btn btn-primary btn-rounded btn-wide"
													onClick={() => generateToken(item.id)}
												>
													{t('CREATE')}
												</button>
											)}
										</td>
										<td className="d-flex justify-content-center">
											<Link to={`/dealer/update/${item.id}`} className="me-2">
												<div className="table-action-button table-action-primary-button">
													<i className="uil-edit-alt"></i>
												</div>
											</Link>
											{/* <div className="table-action-button table-action-danger-button"
												onClick={() => {
													setDeleteModalParams({ id: item.id, index: index });
													setModalDelete(true)
												}}>
												<i className="uil-times"></i>
											</div> */}
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<DeleteModal
				modalDelete={modalDelete}
				setModalDelete={setModalDelete}
				deleteItem={deleteItem}>
			</DeleteModal>

		</>
	)
}

export default Index
